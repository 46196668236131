<template>
 <div :class="windowClass">
   <v-container>
   
  
    <v-row>
      <v-col cols="12">
        dasdasdsss
       </v-col>
     </v-row>   
       <!-- <v-row>
         <v-col cols="12">
        
                  <div class="printable_table">
                   <template v-for="(head, index) in HEADS">
                       <div  :key="index" style=" padding: 10px; float: left; width: 230px; height: 210px; border: 1px solid #ddd; text-align: center;">
                         <div style="width: 150px; height: 150px; margin: auto;">
                           <div :id="'qrcode_container_'+ head.id" class="text-center"></div>
                         </div> 
                         <div>{{head.surname}},  {{head.first_name}} {{head.middle_name}}</div> 
                       </div>
                     </template>
                  </div>
             </v-col>
            
       </v-row> -->
       </v-container>
  
     </div>
   </template>
   <script>
   import {
     mapMutations
   } from 'vuex'
   
     export default {
       data: () => ({
           form: false,
           trans_dailog: false,
           areaform: false,
           state: 'new',
           user: {},
           search:'',
           areas:[],
           precinct:'0001A',
           precincts:[],
           voter:{},
           voters:[],
           items:[],
           headers: [
             { text: 'Prec', value:"prec"},
               { text: 'Last Name', value:"lastname"},
               { text: 'First Name', value:"firstname"},
               { text: 'Middle Name', value:"middlename"},
               { text: 'Ext', value:"ext"},
                { text: 'Status', value:"status"}
           ]
       }),
       beforeCreate: function () {
         if (!this.$session.exists()) {
           this.$router.push('/')
         }
       },
       created() {
          if (this.$session.exists()) {
             this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
             this.setLoggedIn(true) 
             this.getItems()
            
           }
       },
       computed:{
          windowClass() {
           if(this.$store.state.responsive){
             return "va-reponsive"
           } else {
             return "va-reponsive"
           }
         },
         HEADS() {
           var heads = this.items.reduce((res,item)=>{
             if(item.position == "Head of the family") {
               res.push(item)
             }
             return res
           },[])
   
           return heads
         }
       },
       methods: {
         ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
         transEvent(){
           this.trans_dailog = false
         },
         statusIndicator(_time) {
             //var current = this.$moment(_time)
             var a = this.$moment()
               var b = this.$moment(_time)
              var diff = a.diff(b, 'hour')
              if(diff <=  3) {
                 return 'success'
              } else if(diff > 3 && diff < 5) {
                  return '#A5D6A7'
              } else {
                return '#989898'
              }
         },
         CountBarangay(area){
             var _count = area.reduce((res,item)=>{
                   res += item.barangays.length
                   return res
             },0) 
             return _count
         },
         afEvent() {
           this.areaform = false
           this.getAreas()
         },
         formEvent(data){
             this.form = false
             if(data.action != 'close') {
               console.log(data)
                 this.voter = data.voter
                 this.Save()
             }
         },
   
         genCode(){
           for(var i=0;i<this.HEADS.length;i++) {
            let v_id=this.HEADS[i].id
           // let voucher=this.HEADS[i].voucher1
               setTimeout(()=> {
                 let _id = "qrcode_container_" + v_id
                 var qrcode = new window.QRCode(document.getElementById(_id), {
                 width : 150,
                 height : 150  
               });
               qrcode.makeCode(v_id.toString());
         
             }, 100)
           }
         
         },
         
       getItems() {
        this.$http.get("data/list").then(response => {
                    response.data.items != null?this.items = response.data.items:this.items=[]
                    setTimeout(()=> {
             this.genCode()
            }, 1000)
                }).catch(e => {
                 console.log(e.data)
             })
      },
     }
    }  
   </script>